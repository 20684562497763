import { QueryParams, QueryValue } from 'api/APIClient';
import { removeEmptyParam } from 'api/util';
import { CatalogFilterName, PER_PAGE, ZSR_SUGGESTION_FLAG } from 'constants/catalog';
import { THUMBORIZE_IMAGE_FORMAT, THUMBORIZE_IMAGE_QUALITY } from 'constants/images';
import { CatalogType, PageInfo, isSearchPageInfo } from './catalog-page-type';

/**
 * This function will return the param for API call (DOR)
 */
export const getCatalogParams = (
  query: QueryParams,
  pageInfo: PageInfo,
  isEnrichAttributeEnabled = false,
): QueryParams => {
  const params: QueryParams = removeTrackingParams(query);

  const result = {
    ...getHardcodeParams(),

    ...getApiParams(params),

    ...getSearchParams(params, pageInfo, isEnrichAttributeEnabled),

    ...getPageParams(Number(params.page) || 1),

    ...getCategoriesParams(params, pageInfo),

    ...getPageInfoParams(pageInfo),
  } as QueryParams;

  // remove query params of Nextjs
  delete result.slugs;

  return result;
};

const removeTrackingParams = (query: QueryParams) => {
  return Object.keys(query).reduce((result, key) => {
    if (key.startsWith('utm_')) {
      return result;
    }

    return {
      ...result,
      [key]: query[key],
    };
  }, {});
};

export const getHardcodeParams = () => {
  return {
    shop: 'm', // Main shop
    fullFacetCategory: true,
    image_quality: THUMBORIZE_IMAGE_QUALITY,
    image_format: THUMBORIZE_IMAGE_FORMAT,
  };
};

export const getPageInfoParams = (pageInfo?: PageInfo) => {
  const params = {} as QueryParams;

  if (!pageInfo) {
    return params;
  }

  // Common params
  if (pageInfo.segment) {
    params.segment = pageInfo.segment;
  }

  if (pageInfo.brandId) {
    params['brandIds[]'] = pageInfo.brandId;
  }

  if (pageInfo.specialPage) {
    params.specialKey = pageInfo.specialPage;
  }

  if (!pageInfo.categoryId) {
    // By enable this flag, API will return segments data
    // but, category filter will not correct, so, only attach this param if
    // - segment is not exist and
    // - category is not exist
    params.enableRelevanceClassifier = true;
  }

  // get by page type
  switch (pageInfo.catalogType) {
    case CatalogType.ZIS: {
      if (pageInfo.storeInfo.SellerId) {
        params.seller_id = pageInfo.storeInfo.SellerId;
      }

      break;
    }

    case CatalogType.PIP: {
      const { productIndexParams } = pageInfo;

      Object.assign(params, productIndexParams);

      break;
    }
  }

  return params;
};

/**
 * This function will return the query params to show on the url address of browser
 */
export const getCatalogQuery = (query: QueryParams, resetPage?: boolean) => {
  const result = { ...query };

  // by default router.query also include path, slug, slugs which is redundant when push
  delete result.path;
  delete result.slugs;
  delete result.slug;

  if (resetPage) {
    delete result.page;
  }

  return removeEmptyParam(result);
};

export const getPageParams = (page: number) => {
  return {
    offset: (page - 1) * PER_PAGE,
    limit: PER_PAGE,
  };
};

export const getSearchParams = (
  query: QueryParams,
  pageInfo?: PageInfo,
  isEnrichAttributeEnabled = false,
) => {
  if (query.q) {
    return {
      query: query.q,
      abtest:
        pageInfo && isSearchPageInfo(pageInfo) && !isEnrichAttributeEnabled
          ? ZSR_SUGGESTION_FLAG
          : undefined, // HARDCODE value until DOR rollout this feature
    };
  }

  return {};
};

export const getCategoriesParams = (query: QueryParams, pageInfo: PageInfo) => {
  if (isSearchPageInfo(pageInfo) && query.categoryId) {
    return {
      categoryId: query.categoryId,
      parentCategoryId: query.parentCategoryId,
    };
  }

  if (query.categoryId) {
    return {
      categoryId: query.categoryId,
      parentCategoryId: pageInfo.categoryId,
    };
  }

  if (pageInfo.subCategoryId) {
    return {
      categoryId: pageInfo.subCategoryId,
      parentCategoryId: pageInfo.categoryId,
    };
  }

  if (pageInfo.categoryId) {
    return {
      categoryId: pageInfo.categoryId,
    };
  }

  return {};
};

const getApiParams = (query: QueryParams) => {
  const result = { ...query };

  delete result.page; // handle in getPageParams
  delete result.path; // unnecessary data of catalog page
  delete result.slug; // unnecessary data of PIP page
  delete result.slugs; // unnecessary data of ZIS page
  delete result.q; // handle in getSearchParams
  delete result[CatalogFilterName.CATEGORY]; // handled in getCategoriesParams

  return result;
};

export const getCleanQuery = (query: QueryParams) => {
  const result = { ...query };

  // By default router.query also include path (on catalog), slug (on PIP) which is confusing when using
  delete result.path;
  delete result.slugs;
  delete result.slug;

  return result;
};

export const concatABtestParam = (param1: string, param2?: string | string[]) => {
  if (!param2) {
    return [param1];
  }

  if (Array.isArray(param2)) {
    return [param1, ...param2];
  }

  return [param1, param2];
};

export const getSearchTerm = (query: QueryValue, SuggestedSearchQuery?: string) => {
  if (SuggestedSearchQuery) {
    return SuggestedSearchQuery;
  }

  if (typeof query === 'string') {
    return query;
  }

  return undefined;
};
