import { QueryParams } from 'api/APIClient';
import { buildQueryString } from 'api/util';
import { RouteKey } from 'constants/routes';
import { getCountryCode } from './countries';
import { getDomainByLocale, getZaloraDomainWithoutSubdomain } from './domains';
import isServer from './is-server';
import { getLanguage } from './locale';
import { isSearchPage } from './page-type';
import { isEmptyObject } from './validation';

const DOMAIN_LTD = 'https://www.zalora.ltd';
const CHECKOUT_DOMAIN = 'checkout';
const NEXT_DATA_PREFIX = '/_next/data';

export const CheckoutPath = {
  DESKTOP: '/checkout/process/',
  MOBILE: '/checkout/multistep/billing/',
  MOBILE_SIMPLIFIED: '/checkout/multistep/payment/?setDevice=mobile',
};

const VALID_LANGUAGE_SUBDOMAINS = ['zh'];

/**
 * @param slug could product-link or product-link.html or p/product-link
 * @returns product url in the format '/p/product-link'
 */
export const getProductUrlFromSlug = (slug: string): string => {
  if (!slug) {
    return '';
  }

  const prefix = slug.startsWith('/') ? '' : '/';

  if (slug.startsWith('/p/') || slug.startsWith('p/')) {
    return `${prefix}${slug}`;
  }

  const cleanSlug = slug.replace(/\.html/, '');

  // If the slug is absolute URL, just return the clean slug
  if (/^(https|http):\/\/.*/.test(slug)) {
    return cleanSlug;
  }

  // Otherwise return slug with prefix
  return `${prefix}p/${cleanSlug}`;
};

export const getProductUrlFromSku = (sku: string | null): string => {
  if (!sku) {
    return '';
  }

  return `/p/${sku}`;
};

export const setPathname = (pathname: string, newRouteKey: RouteKey) => {
  const [, queryStr] = pathname.split('?');

  return `${newRouteKey}${queryStr ? `?${queryStr}` : ''}`;
};

export const getQueryExcludeKeys = (
  query: Record<string, unknown>,
  keys: string[],
): Record<string, unknown> => {
  const q = Object.keys(query).reduce(
    (obj, key) => {
      if (keys.includes(key)) {
        return obj;
      }

      obj[key] = query[key];

      return obj;
    },
    {} as Record<string, unknown>,
  );

  return q;
};

/**
 *
 * Get full product url from slug
 */
export const getAbsoluteProductUrlFromSlug = (slug: string, locale?: string): string => {
  const productUrl = getProductUrlFromSlug(slug);

  if (locale) {
    return getAbsoluteUrl(productUrl, locale);
  }

  if (isServer()) {
    return productUrl;
  }

  const { origin } = window.location;

  return `${origin}${productUrl}`;
};

/**
 * @param input product url which could be product url of lotus (host/p/product-link) or product url of alita (host/product-link.html)
 * @returns product-link
 */
export const getSlugFromProductUrl = (input: string): string => {
  if (!input) {
    return '';
  }

  try {
    const url = new URL(input);
    const path = url.pathname;

    return path.split('/').pop() || '';
  } catch {
    // in case invalid url
    return '';
  }
};

export const isValidUrlPathname = (pathname: string) => {
  try {
    const validURL = new URL(pathname, DOMAIN_LTD);

    /**
     * validURL.origin !== 'null' - this check will help to avoid pathname with unexpected origin
     * like `zalora://sg/urlc/product/?query=cotton%20on&enable`
     */
    return validURL.pathname !== 'null' && validURL.pathname !== '/' && validURL.origin !== 'null';
  } catch (error) {
    return false;
  }
};

export const isValidUrl = (url: string) => {
  try {
    const validURL = new URL(url);

    return validURL.origin !== 'null';
  } catch (error) {
    return false;
  }
};

/**
 * @param url full product url from alita (Example: https://www.staging.sg.zalora.net/alia-b-camilla-modern-kurung-with-bow-detail-navy-800632.html?from=search)
 * @returns product URL of lotus /p/product-link
 */
export const getProductUrlFromAbsoluteUrl = (url: string): string => {
  const slug = getSlugFromProductUrl(url);

  return getProductUrlFromSlug(slug);
};

/**
 * a slug will alway have '-'
 */
export const isSKU = (skuOrSlug: string): boolean => {
  return !skuOrSlug.includes('-');
};

export const getSecureDomain = (domain: string) => {
  const { protocol } = window.location;

  return protocol + '//' + domain;
};

export const getNewCheckoutHost = () => {
  const { host } = window.location;
  const zaloraDomain = getZaloraDomainWithoutSubdomain(host);

  return `checkout.${zaloraDomain}`;
};

export const getNewCheckoutUrl = (locale: string) => {
  const host = getNewCheckoutHost();
  const lang = getLanguage(locale);
  const defaultPath = 'payments';

  return getSecureDomain(`${host}/${lang}/${defaultPath}`);
};

/**
 * when click on checkout button, we will redirect to checkout domain
 * @param locale
 * @returns For example, checkout.zalora.sg or zh-checkout.zalora.com.hk
 */

export const getSecureCheckoutUrl = (locale: string): string => {
  if (!locale) {
    return '';
  }

  const { host } = window.location;
  const lang = getLanguage(locale);
  const countryCode = getCountryCode()?.toLowerCase() || '';
  const zaloraDomain = getZaloraDomainWithoutSubdomain(host);

  let checkoutDomain = `${CHECKOUT_DOMAIN}.${zaloraDomain}`;

  // zh-checkout and for hk only
  if (VALID_LANGUAGE_SUBDOMAINS.includes(lang) && countryCode === 'hk') {
    checkoutDomain = `${lang}-${CHECKOUT_DOMAIN}.${zaloraDomain}`;
  }

  return getSecureDomain(checkoutDomain);
};

/**
 * when sending product request to DOR, we have to send sku or slug end with html.
 * this function will return correct param base on the input.
 */
export const getCleanedProductSlugOrSku = (slugOrSku: string): string => {
  if (slugOrSku.endsWith('.html')) {
    // if input is product-link.html -> then return product-link.html
    return slugOrSku;
  }

  if (isSKU(slugOrSku)) {
    // if product is sku: 60089AAAEBDAF9GS -> return 60089AAAEBDAF9GS
    return slugOrSku;
  }

  // if it is a slug without .html -> append html to the result.
  return `${slugOrSku}.html`;
};

export const setQueryParams = (
  url: string,
  queryParams: Record<string, string | string[] | undefined>,
  removeParams?: string[],
) => {
  if (!url) {
    return '';
  }

  const [pathname, queryString = ''] = url.split('?');

  const searchParams = new URLSearchParams(queryString);
  const cleanedParams = cleanEmptyQueryParams(queryParams);

  // Force remove params, e.g. to reset "page"
  if (Array.isArray(removeParams)) {
    removeParams.forEach((key) => {
      searchParams.delete(key);
    });
  }

  Object.keys(cleanedParams).forEach((key) => {
    const value = cleanedParams[key];

    if (Array.isArray(value)) {
      // Remove existed key to avoid duplicate
      searchParams.delete(key);

      value.forEach((val) => searchParams.append(key, val));
    } else {
      searchParams.set(key, value);
    }
  });

  if (!searchParams.toString()) {
    return pathname;
  }

  return `${pathname}?${searchParams.toString()}`;
};

export const cleanEmptyQueryParams = (obj: Record<string, unknown>) => {
  const cloneObj = JSON.parse(JSON.stringify(obj));

  // clean falsy values of object
  Object.keys(cloneObj).forEach((key) => {
    if (cloneObj[key] === '' || cloneObj[key] === null || cloneObj[key] === undefined) {
      delete cloneObj[key];
    }
  });

  return cloneObj;
};

export const attachRedirectUrl = (destination: string, redirectUrl: string) => {
  return setQueryParams(destination, { redirectUrl });
};

export const getAbsoluteUrl = (url: string, locale: string) => {
  const domain = getDomainByLocale(locale);

  try {
    const absoluteUrl = new URL(url, domain);

    return absoluteUrl.toString();
  } catch {
    return url;
  }
};

export const getQueryStringFromUrl = (url: string) => {
  if (!url) {
    return '';
  }

  const [, queryString = ''] = url.split('?');

  return queryString;
};

export const getLoginUrl = (params?: QueryParams) => {
  if (!params || isEmptyObject(params)) {
    return `/customer/account/login`;
  }

  return `/customer/account/login?${buildQueryString(params)}`;
};

export const removeLastSlashs = (url: string) => {
  return url.replace(/\/+$/, '');
};

/**
 * Remove leading and trailing slash
 * @param url
 * @returns
 */
export const trimSlashs = (url: string) => {
  return url.replace(/^\/|\/$/g, '');
};

/**
 * This function help to check if the url is a lotus url or not
 * to render Link or a tag
 *
 * This function should be removed when we done update CMS to use lotus url
 */
export const isLotusCatalogUrl = (url: string) => {
  try {
    const matched = url.match(/^(?:https?:\/\/[^\/]+)?(\/[^?#]*)/);

    return matched && matched[1]?.startsWith('/c/');
  } catch (err) {
    return false;
  }
};

export const isNextDataPath = (pathName: string) => {
  return pathName.startsWith(NEXT_DATA_PREFIX);
};

export const getPathnameFromNextDataPath = (pathName: string) => {
  if (!pathName || !isNextDataPath(pathName)) {
    return pathName;
  }

  const regExp = /^\/_next\/data\/(?<env>.+?)\/(?<locale>.+?)\/(?<targetPath>.+)\.json\?.*$/;
  const matched = pathName.match(regExp);

  if (!matched) {
    return pathName;
  }

  const targetPath = matched.groups?.targetPath;

  return targetPath ? `/${targetPath}` : pathName;
};

/**
 * In nextjs, the request url on server could be
 * - the request url from client: /search?q=abc
 * - the request url from nextjs data: /_next/data/.../search.json?q=abc - it happens when back to search page from PDV page
 */
export const isSearchPath = (url: string) => {
  const originalUrl = getPathnameFromNextDataPath(url);

  return isSearchPage(originalUrl);
};
